<template>
    <ODataGrid 
        :dataObject="dsObject"
        hideGridMenu>

        <OColumn field="Version"/>
        <OColumn field="CreatedBy"/>
        <OColumn field="PublishDescription"/>
        <OColumn field="Timestamp" format="General Date Short Time"/>
        
    </ODataGrid>
</template>

<script setup>
    import getVersionsDataObject from 'o365.modules.VersionsDataObject.ts';
    const props = defineProps({
        fileName:{
            type:String,
            required:true,
    
        },
        /**
        *@values files, apps
        */
        type:{
            type:String,
            default:"files"
        },
        version: {
            type:Number,
            default: 0
        }

    });

    const dsObject = getVersionsDataObject(props.fileName,props.type,"id", props.version);
</script>